<template>
  <div>
    <el-dialog
      :title="title + '冬季空调模式'"
      :visible.sync="showing"
      width="750px"
      min-height="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="hide"
    >
      <div v-loading="loading">
        <!-- 一个表单 -->
        <el-form
          ref="formInline"
          :model="formInline"
          @submit.native.prevent
          :rules="rules"
        >
          <!-- <div class="banben"> -->
          <el-form-item label="酒店" prop="hotelId">
            <el-select
              class="text"
              style="width: 100%"
              v-model="formInline.hotelId"
              :disabled="hotel"
              placeholder="请选择酒店"
            >
              <el-option
                v-for="item in hotelList"
                :key="item.id"
                :label="item.hotelName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="房间状态" prop="roomStatus">
            <el-select
              class="text"
              style="width: 100%"
              v-model="formInline.roomStatus"
              placeholder="请选择房间状态"
            >
              <el-option
                v-for="item in roomStatusList"
                :key="item.statusDesc"
                :label="item.roomStatus"
                :value="item.statusDesc"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div class="banben">
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="空调" prop="arStatus">
                <el-radio-group v-model="formInline.arStatus">
                  <el-radio :label="0">关</el-radio>
                  <el-radio :label="1">开</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="空调模式" prop="arModule">
                <el-radio-group v-model="formInline.arModule">
                  <el-radio label="Cold">制冷 </el-radio>
                  <el-radio label="Heat">制热</el-radio>
                  <el-radio label="DeHumid">除湿</el-radio>
                  <el-radio label="Auto">自动</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
          <div class="banben">
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="空调风速" prop="arSpeed">
                <el-radio-group v-model="formInline.arSpeed">
                  <el-radio label="Low">低速</el-radio>
                  <el-radio label="Mid">中速</el-radio>
                  <el-radio label="High">高速</el-radio>
                  <el-radio label="Auto">自动</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="bandanItem" style="width: 45%">
              <el-form-item
                label="空调默认温度"
                prop="arTemperature"
                style="width: 100%"
              >
                <el-input
                  placeholder="请输入空调温度 "
                  v-model="formInline.arTemperature"
                  style="width: 100%"
                  maxlength="10"
                ></el-input>
              </el-form-item>
            </div>
          </div>

          <div class="sure">
            <el-button
              size="small"
              type="primary"
              @click="createStorage('formInline')"
              >确定</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { createInsertUser, updateInsertUser } from "@/api/api2.js";
import { getRequest, postRequest } from "@/api/api.js";

export default {
  data() {
    return {
      //审核弹窗
      title: "",
      showing: false,
      loading: false,
      bedisabled: false,
      hotel: true,
      index: null,
      //表单
      formInline: {},
      hotelList: [],
      roomStatusList: [],
      //表单验证
      rules: {
        hotelId: [
          {
            required: true,
            message: "请选择酒店",
            trigger: "blur",
          },
        ],
        roomStatus: [
          {
            required: true,
            message: "请输入房间状态",
            trigger: "blur",
          },
        ],
        arTemperature: [
          {
            required: true,
            message: "请输入空调温度",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["state"],
  methods: {

    hide() {
      this.fileListUpload = [];
      this.showing = false;
      this.$refs.formInline.resetFields(); //清空表单
    },
    show(item, val, index) {
      this.formInline = {
        arStatus: 0,
        arModule: "Cold",
        arSpeed: "Auto",
        arTemperature: "26",
      };
      let hotelInfor = JSON.parse(window.localStorage.getItem("hotelInfor"));
      this.formInline.hotelId = hotelInfor.hotelId;
      this.getHotelList();
      this.getRoomStatusList();
      this.title = item;
      this.showing = true;

      this.bedisabled = false;
      if (item == "编辑") {
        this.bedisabled = true;
        this.formInline = JSON.parse(JSON.stringify(val));
        this.formInline.arStatus = Number(this.formInline.arStatus);
        // this.formInline.arModule = Number(this.formInline.arModule);
        // this.formInline.arSpeed = Number(this.formInline.arSpeed);
        // this.formInline.roomStatus = Number(this.formInline.roomStatus);
        console.log(this.formInline);
      }
    },
    getHotelList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        offset: 1,
        num: 10000,
      };
      postRequest("selectAllHotel", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.hotelList = res.data.list;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getRoomStatusList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllSysRoomStatus", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.roomStatusList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    createStorage(formData) {
      // 表单验证-----------

      // 新增
      this.$refs[formData].validate((valid) => {
        if (valid) {
          if (this.title == "创建") {
            postRequest("/insertHotelAcWinter", this.formInline).then((res) => {
              if (res.status == 200) {
                this.$message({
                  duration: 5000,
                  message: "创建成功!",
                  type: "success",
                });
                this.hide();
                this.$refs.formInline.resetFields(); //清空表单
                this.$parent.getFounderList();
              } else {
                this.$message({
                  duration: 5000,
                  message: res.message,
                  type: "warning",
                });
              }
            });
          } else {
            this.updateStation(); //调用编辑函数
          }
        } else {
          this.$message({
            duration: 5000,
            message: "请仔细核对填写的内容！",
            type: "warning",
          });
        }
      });
    },
    // 编辑
    updateStation() {
      postRequest("/updateHotelAcWinter", this.formInline).then((res) => {
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "编辑成功!",
            type: "success",
          });
          this.hide();
          this.$refs.formInline.resetFields(); //清空表单
          this.$parent.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "warning",
          });
        }
      });
    },
    //附件
    //上传附件----------------------------------------------
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      let isFlag = true;
      if (!isLt5M) {
        this.$message.error("上传头像文件大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //--------------------------------------------------------------------
    // 成功之后的返回
    handleAvatarSuccess(res, file) {
      this.fileJson.push(res.data);
      this.fileListUpload.push(res.data);
    },

    // 删除之后的返回值
    handleRemove(file, fileList) {
      this.fileJson.forEach((fileId, index) => {
        if (fileId.id == file.id) {
          this.fileJson.splice(index, 1);
          this.fileListUpload.splice(index, 1);
        }
      });
    },
    //预览
    handlePreview(file) {
      let fileName = file.fileName;
      //获取最后一个.的位置
      let index = fileName.lastIndexOf(".");
      //获取后缀
      let ext = fileName.substr(index + 1);
      if (this.isAssetTypeAnImage(ext)) {
        //图片预览
        window.open(
          window.string.BUSINESS_PUBLIC_API_URL +
            "/sys/attachments/getImageByStream/?id=" +
            file.id
        );
      } else {
        //永中预览
        previewUpload(file.id).then(({ data, result, message }) => {
          if (result == 1) {
            var previewUrl = data.filePath;
            window.open(window.string.PUBLIC_VIEW_FILE_API + previewUrl);
          } else {
            this.$message({
              duration: 5000,
              message: message,
              type: "warning",
            });
          }
        });
      }
    },
    isAssetTypeAnImage(ext) {
      return (
        ["png", "jpg", "jpeg", "bmp", "gif"].indexOf(ext.toLowerCase()) !== -1
      );
    },
    handleDowload(file) {
      window.location.href =
        window.string.BUSINESS_PUBLIC_API_URL +
        "/sys/attachments/donwload/?id=" +
        file.id;
    },
  },
};
</script>

<style scoped>
.el-form-item__label {
  display: block !important;
  width: 100% !important;
}
.el-radio-group {
  width: 100%;
}
.el-button {
  width: 320px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.sure {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d8d8d8;
}
.banben {
  display: flex;
  justify-content: space-between;
}
.el-button {
  width: 200px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.el-button:hover {
  transform: scale(1.1);
}
>>> .el-radio-group {
  display: flex;
}
</style>